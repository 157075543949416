exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bibliomining-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/bibliomining.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bibliomining-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bug-classification-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/bug-classification.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bug-classification-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-cbs-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/cbs.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-cbs-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-chemistryworld-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/chemistryworld.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-chemistryworld-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-clickbait-chi-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/clickbait-chi.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-clickbait-chi-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-1-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/defake1.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-1-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-poster-1-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/defake-poster1.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-poster-1-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-dnd-chi-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/dnd-chi.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-dnd-chi-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-edges-flow-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/edges-flow.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-edges-flow-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-podcast-rit-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/podcast-rit.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-podcast-rit-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-1-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/scnews-1.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-1-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-2-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/scnews-2.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-2-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-spectrumnews-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/spectrumnews.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-spectrumnews-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-the-conversation-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/the-conversation.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-the-conversation-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-vice-1-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/vice1.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-vice-1-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-xceptlstm-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/xceptlstm.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-xceptlstm-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-experience-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/blocks/experience.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-experience-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-footer-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/blocks/footer.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-footer-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-clickbait-project-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/clickbait-project.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-clickbait-project-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-defake-project-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/defake-project.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-defake-project-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-pages-about-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-pages-about-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bibliomining-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/bibliomining.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bibliomining-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bug-classification-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/bug-classification.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bug-classification-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-cbs-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/cbs.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-cbs-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-chemistryworld-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/chemistryworld.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-chemistryworld-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-clickbait-chi-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/clickbait-chi.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-clickbait-chi-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-1-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/defake1.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-1-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-poster-1-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/defake-poster1.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-poster-1-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-dnd-chi-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/dnd-chi.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-dnd-chi-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-edges-flow-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/edges-flow.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-edges-flow-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-podcast-rit-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/podcast-rit.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-podcast-rit-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-1-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/scnews-1.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-1-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-2-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/scnews-2.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-2-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-spectrumnews-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/spectrumnews.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-spectrumnews-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-the-conversation-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/the-conversation.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-the-conversation-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-vice-1-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/vice1.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-vice-1-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-xceptlstm-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/xceptlstm.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-xceptlstm-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-experience-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/blocks/experience.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-experience-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-footer-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/blocks/footer.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-footer-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-clickbait-project-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/clickbait-project.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-clickbait-project-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-defake-project-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/defake-project.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-defake-project-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-about-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-about-mdx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bibliomining-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/bibliomining.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bibliomining-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bug-classification-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/bug-classification.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bug-classification-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-cbs-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/cbs.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-cbs-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-chemistryworld-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/chemistryworld.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-chemistryworld-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-clickbait-chi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/clickbait-chi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-clickbait-chi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-1-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/defake1.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-1-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-poster-1-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/defake-poster1.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-poster-1-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-dnd-chi-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/dnd-chi.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-dnd-chi-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-edges-flow-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/edges-flow.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-edges-flow-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-podcast-rit-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/podcast-rit.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-podcast-rit-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-1-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/scnews-1.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-1-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-2-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/scnews-2.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-2-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-spectrumnews-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/spectrumnews.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-spectrumnews-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-the-conversation-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/the-conversation.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-the-conversation-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-vice-1-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/vice1.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-vice-1-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-xceptlstm-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/xceptlstm.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-xceptlstm-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-experience-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/blocks/experience.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-experience-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-footer-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/blocks/footer.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-footer-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-clickbait-project-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/clickbait-project.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-clickbait-project-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-defake-project-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/defake-project.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-defake-project-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-pages-about-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-pages-about-mdx" */),
  "component---src-pages-publicity-index-tsx": () => import("./../../../src/pages/publicity/index.tsx" /* webpackChunkName: "component---src-pages-publicity-index-tsx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bibliomining-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/bibliomining.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bibliomining-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bug-classification-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/bug-classification.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bug-classification-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-cbs-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/cbs.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-cbs-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-chemistryworld-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/chemistryworld.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-chemistryworld-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-clickbait-chi-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/clickbait-chi.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-clickbait-chi-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-1-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/defake1.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-1-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-poster-1-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/defake-poster1.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-poster-1-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-dnd-chi-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/dnd-chi.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-dnd-chi-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-edges-flow-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/edges-flow.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-edges-flow-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-podcast-rit-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/podcast-rit.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-podcast-rit-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-1-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/scnews-1.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-1-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-2-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/scnews-2.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-2-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-spectrumnews-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/spectrumnews.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-spectrumnews-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-the-conversation-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/the-conversation.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-the-conversation-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-vice-1-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/vice1.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-vice-1-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-xceptlstm-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/xceptlstm.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-xceptlstm-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-experience-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/blocks/experience.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-experience-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-footer-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/blocks/footer.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-footer-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-clickbait-project-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/clickbait-project.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-clickbait-project-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-defake-project-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/defake-project.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-defake-project-mdx" */),
  "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-pages-about-mdx": () => import("./../../../src/pages/publicity/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-publicity-mdx-frontmatter-slug-tsx-content-file-path-src-pages-about-mdx" */),
  "component---src-pages-research-index-tsx": () => import("./../../../src/pages/research/index.tsx" /* webpackChunkName: "component---src-pages-research-index-tsx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bibliomining-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/bibliomining.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bibliomining-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bug-classification-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/bug-classification.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-bug-classification-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-cbs-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/cbs.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-cbs-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-chemistryworld-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/chemistryworld.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-chemistryworld-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-clickbait-chi-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/clickbait-chi.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-clickbait-chi-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-1-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/defake1.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-1-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-poster-1-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/defake-poster1.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-defake-poster-1-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-dnd-chi-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/dnd-chi.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-dnd-chi-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-edges-flow-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/edges-flow.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-edges-flow-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-podcast-rit-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/podcast-rit.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-podcast-rit-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-1-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/scnews-1.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-1-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-2-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/scnews-2.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-scnews-2-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-spectrumnews-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/spectrumnews.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-spectrumnews-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-the-conversation-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/the-conversation.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-the-conversation-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-vice-1-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/vice1.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-vice-1-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-xceptlstm-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/articles/xceptlstm.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-xceptlstm-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-experience-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/blocks/experience.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-experience-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-footer-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/blocks/footer.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-blocks-footer-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-clickbait-project-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/clickbait-project.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-clickbait-project-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-defake-project-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/defake-project.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-defake-project-mdx" */),
  "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-pages-about-mdx": () => import("./../../../src/pages/research/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-research-mdx-frontmatter-slug-tsx-content-file-path-src-pages-about-mdx" */)
}

